<script setup lang="ts">
import { computed, onBeforeMount } from "vue";

import { useUserStore, useInvoicesStore } from "@/stores";

import LastInvoiceDetails from "@/components/organisms/LastInvoiceDetails.vue";
import OverdueInvoicesList from "@/components/molecules/OverdueInvoicesList.vue";
import InvoicesList from "@/components/organisms/InvoicesList.vue";

import ChatInfo from "@/assets/images/chart-info.svg";

import { orderInvoices } from "@/utils/orderInvoices";
import { handleError } from "@/utils/handleError";

const userStore = useUserStore();
const invoicesStore = useInvoicesStore();

const hasInvoices = computed(() => {
  const invoices = invoicesStore.invoices ?? [];

  return invoices.length >= 1;
});

const lastInvoice = computed(() => {
  const orderedInvoices = orderInvoices(
    invoicesStore.invoices,
    "reference_month"
  );

  return orderedInvoices[0] ?? {};
});

const overdueInvoices = computed(() => {
  const orderedInvoices =
    orderInvoices(invoicesStore.invoices, "reference_month") ?? [];
  return orderedInvoices.filter(({ status }) => status === "overdue");
});

onBeforeMount(async () => {
  try {
    userStore.setPageTitle("Minhas Faturas");
    userStore.pageLoading = true;

    if (!userStore.selectedInstallation)
      throw Error("Sessão inválida. Favor fazer o login novamente");

    if (!hasInvoices.value) {
      await invoicesStore.getAllInvoices(userStore.selectedInstallation.id);
    }
  } catch (error) {
    handleError(error, userStore.setLoadingError);
  } finally {
    userStore.pageLoading = false;
  }
});
</script>

<template>
  <section class="invoices-page" v-if="!userStore.pageLoading">
    <template v-if="!hasInvoices">
      <div class="invoices-page__not-found">
        <h2 class="invoices-page__not-found__title">Fatura mais recente</h2>
        <div class="invoices-page__not-found__container">
          <div class="invoices-page__not-found__icon">
            <img :src="ChatInfo" />
          </div>
          <div class="invoices-page__not-found__text">
            <p>
              <b>Você não possui nenhuma fatura Metha Energia.</b>
              <br />
              <br />
              Após a aprovação do seu cadastro, nossa energia limpa será enviada
              para você, e as faturas ficarão disponíveis aqui.
            </p>
          </div>
        </div>
      </div>
    </template>
    <template v-else>
      <LastInvoiceDetails :last-invoice="lastInvoice" />
      <OverdueInvoicesList
        v-if="overdueInvoices.length >= 1"
        :overdueInvoices="overdueInvoices"
      />
      <InvoicesList :invoices="invoicesStore.invoices" />
    </template>
  </section>
</template>

<style lang="scss" scoped>
.invoices-page {
  font-family: "DM Sans", sans-serif;
  font-style: normal;

  display: flex;
  flex-direction: column;
  gap: 1.5rem;
  padding-bottom: 2rem;
  margin-top: -19px;

  @media (min-width: 993px) {
    margin-top: 0;
  }

  &__not-found {
    box-shadow: 0px 0px 1px 0px rgba(33, 37, 41, 0.08),
      0px 2px 2px 0px rgba(33, 37, 41, 0.06);
    border-radius: 1rem;
    background: #fff;
    display: flex;
    flex-direction: column;
    gap: 1rem;

    margin: 0 1rem 1.5rem;
    padding: 1.5rem 1rem;
    max-width: calc(100% - 2rem);
    width: 100%;

    @media (min-width: 993px) {
      margin: 1rem auto;
      padding: 1.5rem;
    }

    @media (min-width: 1020px) {
      width: calc(100% - 6rem);
      max-width: 1180px;
    }

    &__title {
      color: #6c757d;
      font-weight: 500;
      font-size: 18px;
      line-height: 24px;

      @media (min-width: 993px) {
        font-size: 20px;
      }
    }

    &__container {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      gap: 1rem;
      padding: 2rem 1rem 1rem;
      background-color: #e9ecef;
      border-radius: 1.125rem;

      @media (min-width: 993px) {
        flex-direction: row;
        gap: 3rem;
        padding: 3.5rem 5rem;
      }
    }

    &__icon {
      font-size: 80px;
      width: 80px;
      height: 80px;
      display: flex;
      justify-content: center;
      align-items: center;
    }

    &__text {
      color: #000;
      font-weight: 400;
      font-size: 14px;
      line-height: 18px;
      text-align: center;

      @media (min-width: 993px) {
        font-size: 20px;
        line-height: 24px;
        text-align: left;
      }
    }
  }
}
</style>
