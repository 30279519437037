<script setup lang="ts">
import { defineProps } from "vue";

import { MeButton, MeIcon } from "@/libs/metha-components";

import { useUserStore } from "@/stores";

interface ServiceChannelItem {
  icon: string;
  title: string;
  text: string;
  button_text: string;
  url: string | null;
}

defineProps<{
  item: ServiceChannelItem;
}>();

const userStore = useUserStore();

function openChat() {
  userStore.openSunshineChat();
}
</script>

<template>
  <div class="service-channel-item">
    <h3 class="service-channel-item__title">
      <span class="service-channel-item__icon">
        <MeIcon>{{ item.icon }}</MeIcon>
      </span>
      {{ item.title }}
    </h3>
    <p class="service-channel-item__text">
      {{ item.text }}
    </p>
    <a
      class="service-channel-item__url"
      :href="item.url"
      target="_blank"
      v-if="typeof item.url === 'string'"
    >
      {{ item.button_text }}
    </a>
    <div v-else>
      <MeButton @click="openChat" class="service-channel-item__button">
        {{ item.button_text }}
      </MeButton>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.service-channel-item {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  gap: 1rem;

  width: 100%;

  @media (min-width: 993px) {
    max-width: 280px;
  }

  &__icon {
    display: flex;
    align-items: center;
    justify-content: center;

    font-size: 24px;
    width: 24px;
    height: 24px;

    @media (min-width: 993px) {
      font-size: 30px;
      width: 30px;
      height: 30px;
    }
  }

  &__title {
    display: flex;
    align-items: flex-end;
    gap: 1rem;

    color: #000;
    line-height: 24px;
    font-weight: 700;
    font-size: 18px;

    @media (min-width: 993px) {
      font-size: 20px;
    }
  }

  &__text {
    color: #343a40;
    font-size: 16px;
    line-height: 24px;
    font-weight: 400;
  }

  &__url {
    color: #000;
    font-weight: 700;
    text-decoration: none;

    display: flex;
    align-items: center;

    font-size: 16px;
    line-height: 22px;
    height: 35px;

    @media (min-width: 1280px) {
      font-size: 18px;
      line-height: 27px;
      height: 40px;
    }

    @media (min-width: 1400px) {
      height: 46px;
    }

    &:hover {
      color: #2db699;
      text-decoration: underline;
      cursor: pointer;
    }
  }

  &__button {
    width: 100%;

    @media (min-width: 993px) {
      width: fit-content;
    }
  }
}
</style>
