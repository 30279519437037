<script setup lang="ts">
import { ref, computed } from "vue";

import { MeIcon, useBreakpoints } from "@/libs/metha-components";

import { FlatInstallation } from "@/types/InstallationTypes";
import { useUserStore } from "@/stores";
import InstallationNavSelectOption from "@/components/atoms/InstallationNavSelectOption.vue";

const userStore = useUserStore();
const { lgAndUp } = useBreakpoints();

const open = ref(false);

const selectedInstallation = computed(() => {
  return userStore.selectedInstallation;
});

const options = computed(() => {
  return userStore.installations ?? [];
});

function handleClick(value: FlatInstallation) {
  userStore.switchInstallation(value);
  open.value = false;
}

function closeInstallationNavSelect() {
  open.value = false;
}
</script>

<template>
  <div
    class="installations-nav-select"
    v-click-outside="closeInstallationNavSelect"
  >
    <div
      :class="[
        'installations-nav-select__label',
        { 'installations-nav-select__label--open': open },
      ]"
      @click="open = !open"
    >
      <div class="installations-nav-select__label__wrapper">
        <span class="installations-nav-select__label__wrapper__label">
          Instalação{{
            lgAndUp ? "" : `: ${selectedInstallation.installation_number}`
          }}
        </span>
        <span class="installations-nav-select__label__wrapper__value">
          {{
            lgAndUp
              ? selectedInstallation.installation_number
              : selectedInstallation.address
          }}
        </span>
      </div>
      <div
        :class="[
          'installations-nav-select__label__icon',
          { 'installations-nav-select__label__icon--rotate': open },
        ]"
      >
        <MeIcon>chevron-bottom</MeIcon>
      </div>
    </div>
    <div v-if="open" class="installations-nav-select__options">
      <template
        v-for="(installation, index) in options"
        :key="`installations-nav-select-option-${index}`"
      >
        <InstallationNavSelectOption
          :installation="installation"
          @click="() => handleClick(installation)"
        />
        <div
          v-if="index < options.length - 1"
          class="installations-nav-select__options__divider-line"
        ></div>
      </template>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.installations-nav-select {
  position: relative;
  width: 100%;

  margin-top: -8px;

  @media (min-width: 993px) {
    width: fit-content;
    margin-top: 0;
  }

  &__label {
    display: flex;
    gap: 0.25rem;
    padding: 0.75rem 0.5rem;
    cursor: pointer;

    @media (min-width: 993px) {
      background: #e9ecef;
      border-radius: 0.5rem;

      &:hover {
        background: #f5f5f5;
      }
    }

    &--open {
      @media (min-width: 993px) {
        background: #f5f5f5;
        border-radius: 0.5rem 0.5rem 0rem 0rem;
      }
    }

    &__wrapper {
      display: flex;
      flex-direction: column;
      gap: 2px;

      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;

      &__label {
        color: #000;
        line-height: 15.62px;
        text-transform: uppercase;
        font-size: 12px;
        font-weight: 700;
        letter-spacing: 1px;

        @media (min-width: 993px) {
          font-size: 10px;
          font-weight: 400;
          letter-spacing: 1.5px;
        }
      }

      &__value {
        color: #000;
        font-size: 14px;
        line-height: 24px;
        font-weight: 400;
        max-width: 100%;

        @media (min-width: 993px) {
          font-weight: 700;
        }
      }
    }

    &__icon {
      font-size: 24px;

      display: flex;
      flex-direction: column;
      justify-content: center;

      transition: transform 0.3s ease-in-out;

      &--rotate {
        transform: rotate(180deg);
      }
    }
  }

  &__options {
    position: absolute;
    top: 98%;
    left: 0;
    z-index: 2;

    width: 90vw;
    min-width: 300px;
    max-width: 432px;

    background: #fff;
    box-shadow: 0px 0px 1px 0px rgba(33, 37, 41, 0.32),
      0px 4px 6px 0px rgba(33, 37, 41, 0.2);
    border-radius: 0.5rem;
    padding: 12px;

    @media (min-width: 993px) {
      border-radius: 0rem 0.5rem 0.5rem 0.5rem;
      padding: 16px;
    }

    &__divider-line {
      width: 100%;
      height: 1px;
      background-color: rgba(0, 0, 0, 0.1);
      margin-bottom: 8px;
    }
  }
}
</style>
