import cemigLogo from "@/assets/images/supplier/cemig-logo.png";
import cpflPaulistaLogo from "@/assets/images/supplier/cpfl-paulista-logo.png";
import energisaMtLogo from "@/assets/images/supplier/energisa-mt-logo.png";

function getSupplierLogo(supplier: string) {
  const logos = {
    "cpfl-paulista": cpflPaulistaLogo,
    "energisa-mt": energisaMtLogo,
    default: cemigLogo,
  };

  if (Object.keys(logos).includes(supplier)) {
    return logos[supplier as keyof typeof logos];
  }

  return logos.default;
}

export { getSupplierLogo };
