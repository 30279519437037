<script setup lang="ts">
import { defineProps, defineEmits, ref, computed, PropType } from "vue";

import { MeIcon } from "@/libs/metha-components";

const props = defineProps({
  label: {
    type: String,
  },
  icon: {
    type: String,
  },
  primaryOptions: {
    type: Array as PropType<Array<number>>,
    required: true,
  },
  secondaryOptions: {
    type: Object as PropType<{ [key: number]: string[] }>,
  },
  getValue: {
    type: Function,
    default: (item: number | string) => {
      return item;
    },
  },
  getName: {
    type: Function,
    default: (item: number | string) => {
      return item;
    },
  },
  modelValue: {
    type: [String, Number],
    required: true,
  },
  selectedPrimary: {
    type: Number,
    required: true,
  },
});

const emit = defineEmits(["update:modelValue", "update:selectedPrimary"]);

const isOpen = ref(false);
const actualPrimary = ref(props.selectedPrimary);

const isOnFirstSelectPage = computed(
  () =>
    props.primaryOptions[props.primaryOptions.length - 1] !==
    actualPrimary.value
);

const isOnLastSelectPage = computed(
  () => props.primaryOptions[0] !== actualPrimary.value
);

function handleChange(item: string | number) {
  const value = props.getValue(item);
  emit("update:modelValue", value);
  isOpen.value = false;
}

function goToNextPrimaryOption() {
  const currentOptionIndex = props.primaryOptions.findIndex(
    (el: number) => el === actualPrimary.value
  );

  if (currentOptionIndex === 0) {
    return;
  }

  actualPrimary.value = props.primaryOptions[currentOptionIndex - 1];
}

function goToPreviousPrimaryOption() {
  const currentOptionIndex = props.primaryOptions.findIndex(
    (el: number) => el === actualPrimary.value
  );

  if (currentOptionIndex === props.primaryOptions.length - 1) {
    return;
  }

  actualPrimary.value = props.primaryOptions[currentOptionIndex + 1];
}

function closeComposedSelect() {
  isOpen.value = false;
}
</script>

<template>
  <div class="ca-composed-select" v-click-outside="closeComposedSelect">
    <div class="ca-composed-select__label-container" @click="isOpen = !isOpen">
      <p v-if="icon && icon.length" class="ca-composed-select__icon">
        <MeIcon>{{ icon }}</MeIcon>
      </p>
      <p class="ca-composed-select__label">
        {{ label }}
      </p>
      <span
        :class="[
          'ca-composed-select__switch-icon',
          { 'ca-composed-select__switch-icon--open': isOpen },
        ]"
      >
        <MeIcon>chevron-bottom</MeIcon>
      </span>
    </div>
    <div class="ca-composed-select__options" v-if="isOpen">
      <div class="ca-composed-select__primary-options">
        <span :class="['ca-composed-select__primary-options__button']">
          <MeIcon
            v-show="isOnFirstSelectPage"
            @click="goToPreviousPrimaryOption"
          >
            arrow-left-circle
          </MeIcon>
        </span>
        <span class="ca-composed-select__primary-options__label">
          {{ actualPrimary }}
        </span>
        <span :class="['ca-composed-select__primary-options__button']">
          <MeIcon v-show="isOnLastSelectPage" @click="goToNextPrimaryOption">
            arrow-right-circle
          </MeIcon>
        </span>
      </div>
      <template v-if="secondaryOptions">
        <div
          v-for="(item, index) in secondaryOptions[actualPrimary]"
          :key="`${index}`"
          @click="() => handleChange(item)"
          class="ca-composed-select__options__item"
        >
          {{ getName(item) }}
        </div>
      </template>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.ca-composed-select {
  position: relative;

  &__selected-option {
    color: #000;
    font-size: 18px;
    line-height: 27px;
    text-align: left;
  }

  &__label-container {
    cursor: pointer;
    display: flex;
    align-items: baseline;
    gap: 0.5rem;
  }

  &__icon {
    display: flex;
    align-self: center;
    font-size: 20px;
    padding-bottom: 4px;
  }

  &__label {
    color: #000;
    font-weight: 700;
    line-height: 24px;
    font-size: 17px;

    @media (min-width: 993px) {
      font-size: 20px;
    }
  }

  &__switch-icon {
    align-self: center;
    font-size: 20px;
    transition: 0.5s ease;
    display: flex;

    &--open {
      transform: rotate(180deg);
    }
  }

  &__options {
    position: absolute;
    right: 0;
    top: 100%;
    z-index: 999;

    color: #000;
    font-size: 18px;
    line-height: 27px;
    text-align: left;

    background: #fff;
    border-radius: 0.5rem;
    box-shadow: 0px 0px 1px 0px rgba(33, 37, 41, 0.32),
      0px 4px 6px 0px rgba(33, 37, 41, 0.2);
    min-width: 152px;
    width: max-content;
    padding: 0.5rem;

    &__item {
      padding: 0.5rem;
      cursor: pointer;

      &:hover {
        background-color: #f2f2f2;
      }
    }
  }

  &__primary-options {
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 1.5rem;

    background-color: #e9ecef;
    border-radius: 0.5rem;
    padding: 0.5rem;

    &__label {
      color: #000000;
      font-size: 18px;
      line-height: 27px;
      font-weight: 400;
      cursor: default;
    }

    &__button {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      color: #374151;
      font-size: 18px;
      cursor: pointer;

      min-width: 25px;
    }
  }
}
</style>
