<template>
  <div class="skeleton-loading">
    <div class="skeleton-loading__full-card">
      <div class="skeleton-loading__thin">
        <div class="skeleton-loading__thin__avatar">
          <p
            class="skeleton-loading__thin__paragraph skeleton-loading__thin__paragraph--small skeleton"
          ></p>
          <p
            class="skeleton-loading__thin__paragraph skeleton-loading__thin__paragraph--medium skeleton"
          ></p>
        </div>
        <div
          class="skeleton-loading__thin__paragraph skeleton-loading__thin__paragraph--full skeleton"
        ></div>
      </div>
    </div>
    <div class="skeleton-loading__container">
      <div class="skeleton-loading__large skeleton-loading__half-card">
        <div class="skeleton-loading__large__header">
          <div class="skeleton-loading__large__header__title skeleton"></div>
          <div class="skeleton-loading__large__header__tag skeleton"></div>
        </div>
        <div class="skeleton-loading__large__content">
          <div class="skeleton-loading__large__content__left">
            <div class="skeleton-loading__large__content__small skeleton"></div>
            <div class="skeleton-loading__large__content__large skeleton"></div>
          </div>
          <div class="skeleton-loading__large__content__right">
            <div class="skeleton-loading__large__content__small skeleton"></div>
            <div class="skeleton-loading__large__content__large skeleton"></div>
          </div>
        </div>
        <div class="skeleton-loading__large__graph skeleton"></div>
        <div class="skeleton-loading__large__content">
          <div class="skeleton-loading__large__content__left">
            <div class="skeleton-loading__large__content__small skeleton"></div>
            <div class="skeleton-loading__large__content__large skeleton"></div>
          </div>
          <div class="skeleton-loading__large__content__right">
            <div class="skeleton-loading__large__content__small skeleton"></div>
            <div class="skeleton-loading__large__content__large skeleton"></div>
          </div>
        </div>
      </div>
      <div class="skeleton-loading__container__side">
        <div class="skeleton-loading__large skeleton-loading__full-card">
          <div class="skeleton-loading__large__side">
            <div class="skeleton-loading__large__side__title skeleton"></div>
            <div class="skeleton-loading__large__side__subtitle skeleton"></div>
            <div
              class="skeleton-loading__large__side__description skeleton"
            ></div>
          </div>
        </div>
        <div class="skeleton-loading__large skeleton-loading__full-card">
          <div class="skeleton-loading__large__side">
            <div class="skeleton-loading__large__side__title skeleton"></div>
            <div class="skeleton-loading__large__side__subtitle skeleton"></div>
            <div
              class="skeleton-loading__large__side__description skeleton"
            ></div>
          </div>
        </div>
        <div class="skeleton-loading__large skeleton-loading__full-card">
          <div class="skeleton-loading__large__side">
            <div class="skeleton-loading__large__side__title skeleton"></div>
            <div class="skeleton-loading__large__side__subtitle skeleton"></div>
            <div
              class="skeleton-loading__large__side__description skeleton"
            ></div>
          </div>
        </div>
      </div>
    </div>
    <div class="skeleton-loading__full-card">
      <div class="skeleton-loading__faq">
        <div class="skeleton-loading__faq__title skeleton"></div>
        <div class="skeleton-loading__faq__item">
          <div class="skeleton-loading__faq__item__avatar skeleton"></div>
          <div class="skeleton-loading__faq__item__text--small skeleton"></div>
        </div>
        <div class="skeleton-loading__faq__item">
          <div class="skeleton-loading__faq__item__avatar skeleton"></div>
          <div class="skeleton-loading__faq__item__text--medium skeleton"></div>
        </div>
        <div class="skeleton-loading__faq__item">
          <div class="skeleton-loading__faq__item__avatar skeleton"></div>
          <div class="skeleton-loading__faq__item__text--small skeleton"></div>
        </div>
        <div class="skeleton-loading__faq__item">
          <div class="skeleton-loading__faq__item__avatar skeleton"></div>
          <div class="skeleton-loading__faq__item__text--large skeleton"></div>
        </div>
        <div class="skeleton-loading__faq__item">
          <div class="skeleton-loading__faq__item__avatar skeleton"></div>
          <div class="skeleton-loading__faq__item__text--medium skeleton"></div>
        </div>
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.skeleton-loading {
  width: calc(100% - 2rem);
  margin: -10px auto 0;
  height: 100%;
  max-width: 100vw;

  @media (min-width: 993px) {
    max-width: 950px;
    margin: -16px auto 1.5rem;
  }

  @media (min-width: 1020px) {
    max-width: 1180px;
    width: calc(100% - 6rem);
  }

  &__full-card {
    width: 100%;
    padding: 1rem 1.5rem 1.5rem;

    background-color: white;
    box-shadow: 0px 0px 1px 0px rgba(33, 37, 41, 0.08),
      0px 2px 2px 0px rgba(33, 37, 41, 0.06);

    border-radius: 1rem;

    &:not(:last-child) {
      margin-bottom: 2rem;
    }
  }

  &__half-card {
    width: 100%;
    margin: 0 auto;
    padding: 1rem 1.5rem 1.5rem;

    background-color: white;
    box-shadow: 0px 0px 1px 0px rgba(33, 37, 41, 0.08),
      0px 2px 2px 0px rgba(33, 37, 41, 0.06);

    border-radius: 1rem;

    @media (min-width: 993px) {
      width: 50%;
    }
  }

  &__thin {
    display: flex;
    gap: 1rem;

    &__avatar {
      display: flex;
      flex-direction: column;
      gap: 0.5rem;
    }

    &__paragraph {
      &--small {
        height: 8.75px;
        width: 73px;
      }

      &--medium {
        height: 12.25px;
        width: 94px;
      }

      &--full {
        height: 30px;
        width: 100%;
      }
    }
  }

  &__container {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    gap: 1rem;
    flex-direction: column;

    @media (min-width: 1024px) {
      flex-direction: row;
    }

    &__side {
      width: 100%;
      display: flex;
      flex-direction: column;
      margin-bottom: 2rem;

      @media (min-width: 993px) {
        width: 50%;
      }
    }
  }

  &__large {
    display: flex;
    flex-direction: column;
    gap: 2rem;

    &__header {
      display: flex;
      align-items: flex-start;
      justify-content: space-between;

      &__title {
        height: 17.5px;
        width: 190px;
      }

      &__tag {
        width: 77px;
        height: 8.75px;
      }
    }

    &__content {
      display: flex;
      justify-content: space-between;

      &__right,
      &__left {
        display: flex;
        flex-direction: column;
        gap: 0.75rem;
      }

      &__right {
        align-items: flex-end;
      }

      &__left {
        align-items: flex-start;
      }

      &__small {
        width: 84px;
        height: 14px;
      }

      &__large {
        width: 107px;
        height: 21px;
      }
    }

    &__graph {
      width: 90%;
      max-width: 500px;
      height: 200px;
      margin: 0 auto;
    }

    &__side {
      width: 100%;
      display: flex;
      flex-direction: column;
      gap: 1rem;

      &__title {
        width: 90%;
        max-width: 399px;
        height: 14px;
      }

      &__subtitle {
        width: 70%;
        max-width: 225px;
        height: 19px;
      }

      &__description {
        width: 50%;
        max-width: 138px;
        height: 24px;
      }
    }
  }

  &__faq {
    display: flex;
    flex-direction: column;
    gap: 1.5rem;

    &__title {
      width: 80%;
      max-width: 450px;
      height: 25px;
      margin: 1rem 0;
    }

    &__item {
      width: 100%;
      display: flex;
      align-items: center;
      padding-left: 1rem;
      gap: 1rem;

      &__avatar {
        width: 20%;
        max-width: 70px;
        aspect-ratio: 1 / 1;
      }

      &__text {
        &--small,
        &--medium,
        &--large {
          height: 17px;
          flex-grow: 1;
        }

        &--small {
          width: 50%;
          max-width: 320px;
        }

        &--medium {
          width: 70%;
          max-width: 461px;
        }

        &--large {
          width: 90%;
          max-width: 609px;
        }
      }
    }
  }
}

.skeleton {
  border-radius: 1rem;

  position: relative;
  overflow: hidden;
  background-color: #dddbdd;

  &::after {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    transform: translateX(-100%);
    background-image: linear-gradient(
      90deg,
      rgba(#fff, 0) 0,
      rgba(#fff, 0.2) 20%,
      rgba(#fff, 0.5) 60%,
      rgba(#fff, 0)
    );
    animation: shimmer 3s infinite;
    content: "";
  }

  @keyframes shimmer {
    100% {
      transform: translateX(100%);
    }
  }
}
</style>
