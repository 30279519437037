export const navLinks = [
  // {
  //   title: "Início",
  //   icon: "home",
  //   route: "/",
  // },
  {
    title: "Faturas",
    icon: "document",
    route: "/faturas",
  },
  // {
  //   title: "Instalações",
  //   icon: "building",
  //   route: "/instalacoes",
  // },
  // {
  //   title: "Conta",
  //   icon: "user",
  //   route: "/conta",
  // },
  {
    title: "Ajuda",
    icon: "question-mark-circle",
    route: "/central-de-ajuda",
  },
];
