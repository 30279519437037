<script setup lang="ts">
import { defineProps, onBeforeMount, ref } from "vue";
import { MeIcon } from "@/libs/metha-components";
import { Article } from "@/types/Faq";
import { useFaqStore } from "@/stores/faq";

import CaSpinner from "@/components/atoms/CaSpinner.vue";

const props = defineProps<{
  article: Article;
}>();

const faqStore = useFaqStore();

const voted = ref(false);
const voteValue = ref("");
const voteError = ref("");
const loading = ref(false);

async function vote(vote: "up" | "down") {
  if (voted.value || loading.value) return;
  voteError.value = "";
  loading.value = true;

  try {
    await faqStore.voteArticle(props.article.id, vote);
    voteValue.value = vote;
    voted.value = true;
  } catch (error) {
    voteError.value =
      "Ocorreu um erro ao registrar seu voto. Por favor vote novamente.";
    voted.value = false;
    voteValue.value = "";
  } finally {
    loading.value = false;
  }
}

onBeforeMount(() => {
  voted.value = false;
  voteValue.value = "";
  voteError.value = "";
});
</script>

<template>
  <div class="rate-article">
    <div class="rate-article__container">
      <p class="rate-article__title">Essa resposta foi útil?</p>
      <div class="rate-article__loading" v-if="loading">
        <CaSpinner :size="15" :width="5" />
      </div>
      <div class="rate-article__buttons" v-else-if="!voted">
        <span
          class="rate-article__rounded-background rate-article--success"
          style="padding-bottom: 2px"
          @click="() => vote('up')"
        >
          <MeIcon>thumbs-up</MeIcon>
        </span>
        <span
          class="rate-article__rounded-background rate-article--error"
          style="padding-top: 2px"
          @click="() => vote('down')"
        >
          <MeIcon>thumbs-down</MeIcon>
        </span>
      </div>
      <div
        :class="[
          `rate-article__thanks ${
            voteValue === 'up' ? 'rate-article--success' : 'rate-article--error'
          }`,
        ]"
        v-else
      >
        Obrigado pelo feedback!
      </div>
    </div>
    <p v-if="voteError?.length > 0" class="rate-article--error-message">
      {{ voteError }}
    </p>
  </div>
</template>

<style lang="scss" scoped>
.rate-article {
  &__container {
    display: flex;
    align-items: center;
    gap: 1rem;
  }

  &__title {
    color: #000;
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
  }

  &__loading {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 32px;
    height: 32px;
  }

  &__buttons {
    display: flex;
    align-items: center;
    gap: 1.5rem;
  }

  &__rounded-background {
    display: flex;
    align-items: center;
    justify-content: center;

    cursor: pointer;

    width: 32px;
    height: 32px;
    border-radius: 100%;

    color: #fff;
    font-size: 18px;
  }

  &__thanks {
    display: flex;
    align-items: center;
    padding: 0 0.5rem;
    height: 32px;

    white-space: nowrap;
    color: #fff;
    border-radius: 1rem;
  }

  &--success {
    background: #2db699;
  }

  &--error {
    background: #ff324b;
  }

  &--error-message {
    color: #ff324b;
    font-size: 12px;
    margin-bottom: 4px;
  }
}
</style>
