<script setup lang="ts">
import { onBeforeMount } from "vue";
import { useUserStore, useFaqStore } from "@/stores";

import FaqSearchBar from "@/components/organisms/FaqSearchBar.vue";
import GridFaq from "@/components/organisms/GridFaq.vue";
import ServiceChannels from "@/components/organisms/ServiceChannels.vue";

const userStore = useUserStore();
const faqStore = useFaqStore();

onBeforeMount(async () => {
  userStore.setPageTitle("Como podemos ajudar?");
  faqStore.clearSearchResults();
  faqStore.cleanSelectedArticle();
});
</script>

<template>
  <section>
    <FaqSearchBar />
    <GridFaq />
    <ServiceChannels />
  </section>
</template>
