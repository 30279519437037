export const maskEmail = (email: string) => {
  if (!email) return;

  const [user, mail] = email.split("@");

  let middleIndex = Math.floor(user.length / 2);

  const middleFilter = "*".repeat(middleIndex);

  if (user.length % 2 !== 0) middleIndex += 1;

  const filtered = `${user.substring(0, middleIndex)}${middleFilter}@${mail}`;

  return filtered;
};
