<script setup lang="ts">
import { ref, watch, defineProps } from "vue";
import type { PropType } from "vue";
import CaComposedSelect from "@/components/atoms/CaComposedSelect.vue";

import { getMonthNameFromReferenceMonth } from "@/utils/format";
import { useInvoicesStore } from "@/stores";
import { useRouter } from "vue-router";

const router = useRouter();

const props = defineProps({
  label: {
    type: String,
  },
  icon: {
    type: String,
  },
  primaryOptions: {
    type: Array as PropType<Array<number>>,
    required: true,
  },
  secondaryOptions: {
    type: Object as PropType<{ [key: number]: string[] }>,
  },
  getValue: {
    type: Function,
    default: (item: number | string) => item,
  },
  getName: {
    type: Function,
    default: (item: number | string) => item,
  },
  currentYear: {
    type: Number,
    required: true,
  },
  currentInvoice: {
    type: String,
    required: true,
  },
});

const invoicesStore = useInvoicesStore();
const value = ref(props.currentInvoice);
const selectedYear = ref(props.currentYear);

function getName(item: string) {
  if (!item || !item.length) return item;

  return getMonthNameFromReferenceMonth(item);
}

function getCompleteReferenceMonthName(reference_month: string) {
  if (!reference_month) return "";

  const [, year] = reference_month.split("/");
  return `${getMonthNameFromReferenceMonth(reference_month)}/${year}`;
}

watch(value, async () => {
  await invoicesStore.getInvoiceByReferenceMonth(value.value);
  router.push(`${invoicesStore.selectedInvoice.id}`);
});
</script>

<template>
  <div class="view-invoice-composed-select">
    <p class="view-invoice-composed-select__title-desktop">
      Fatura referente a
    </p>
    <p class="view-invoice-composed-select__title-mobile">Referente a</p>
    <CaComposedSelect
      :label="getCompleteReferenceMonthName(currentInvoice)"
      :selectedPrimary="selectedYear"
      :primaryOptions="primaryOptions"
      v-model="value"
      :secondaryOptions="secondaryOptions"
      :getName="getName"
    />
  </div>
</template>

<style lang="scss" scoped>
.view-invoice-composed-select {
  background-color: #fff;
  margin: -16px 1rem 1.5rem;
  box-shadow: 0px 0px 1px 0px rgba(33, 37, 41, 0.08),
    0px 2px 2px 0px rgba(33, 37, 41, 0.06);
  border-radius: 1rem;
  padding: 1rem 0.75rem;

  display: flex;
  gap: 7px;
  align-items: baseline;
  justify-content: center;

  color: #6c757d;
  font-size: 18px;
  line-height: 24px;
  font-weight: 500;

  @media (min-width: 993px) {
    background-color: unset;
    box-shadow: none;
    margin: 1.5rem auto;
    width: fit-content;
  }

  &__title {
    &-desktop {
      display: none;

      @media (min-width: 993px) {
        display: inline-block;
      }
    }
    &-mobile {
      @media (min-width: 993px) {
        display: none;
      }
    }
  }
}
</style>
