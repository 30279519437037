<script setup lang="ts">
import { defineProps, ref } from "vue";
import { useRouter } from "vue-router";

import {
  MeIcon,
  // MeIconNotification,
  useBreakpoints,
} from "@/libs/metha-components";

import { useUserStore } from "@/stores";
import InstallationsNavSelect from "@/components/molecules/InstallationsNavSelect.vue";
import MobileActionButton from "@/components/molecules/MobileActionButton.vue";

defineProps<{
  title: string;
  notificationNumber: number;
  hideInstallationSwitchComponent?: boolean;
}>();

const router = useRouter();
const userStore = useUserStore();
const { lgAndUp } = useBreakpoints();

async function logOut() {
  await userStore.logOutUser();
  router.push("/login");
}
</script>

<template>
  <div :class="['me-header', { 'me-header__background': !lgAndUp }]">
    <div class="me-header__background">
      <div
        :class="[
          'me-header__container me-header__information',
          {
            'me-header--showing-menu': lgAndUp,
            'me-header__information--add-padding-bottom':
              !lgAndUp && hideInstallationSwitchComponent,
          },
        ]"
      >
        <div class="me-header__wrapper-menu-title">
          <template v-if="!lgAndUp">
            <MobileActionButton />
          </template>
          <h1 class="me-header__greeting">{{ title }}</h1>
        </div>
        <div class="me-header__options">
          <div class="me-header__options__item">
            <!-- <MeIconNotification
              :size="26"
              icon="bell"
              :notificationNumber="notificationNumber"
            />
            <p>Alertas</p> -->
          </div>
          <div class="me-header__options__item" @click="logOut">
            <span class="me-header__options__item__icon">
              <MeIcon>sign-out</MeIcon>
            </span>
            <p>Sair</p>
          </div>
        </div>
      </div>
    </div>
    <div v-if="!hideInstallationSwitchComponent" class="me-header__container">
      <div
        v-if="!userStore.pageLoading || !lgAndUp"
        class="me-header__installation-select"
      >
        <InstallationsNavSelect />
        <p class="me-header__installation-select__address">
          {{ userStore.selectedInstallation.address }}
        </p>
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.me-header {
  width: 100%;
  max-width: 100vw;

  &__background {
    background-color: #ffcc12;
  }

  &__container {
    width: calc(100% - 2rem);
    margin: 0 auto;

    @media (min-width: 1020px) {
      max-width: 1180px;
      width: calc(100% - 6rem);
    }
  }

  &__information {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 1rem;
    padding-top: 2.125rem;

    @media (min-width: 993px) {
      gap: 1.5rem;
      padding: 3rem 1.5rem 54px;
    }

    &--add-padding-bottom {
      padding-bottom: 2rem;
    }
  }

  &__installation-select {
    width: 100%;
    display: flex;
    flex-direction: row-reverse;
    align-items: center;
    justify-content: space-between;
    gap: 4px;
    padding-bottom: 2.25rem;
    margin-top: 16px;
    z-index: 99;

    @media (min-width: 993px) {
      align-items: center;
      justify-content: flex-start;
      flex-direction: row;
      background-color: #fff;
      border-radius: 1rem;
      padding: 1rem 1.5rem;
      margin-top: -31px;
      gap: 1rem;
    }

    &__address {
      display: none;

      @media (min-width: 993px) {
        display: block;
        color: #000;
        font-style: normal;
        font-weight: 400;
        color: #6c757d;
        font-size: 18px;
        line-height: 27px;
      }
    }
  }

  &__wrapper-menu-title {
    display: flex;
    align-items: center;
    gap: 24px;
  }

  &__greeting {
    color: #000;
    font-family: DM Sans;
    font-style: normal;
    font-weight: 700;
    line-height: 1.25;
    font-size: 20px;

    @media (min-width: 993px) {
      font-size: 36px;
    }
  }

  &__options {
    display: none;

    @media (min-width: 993px) {
      display: flex;
      align-items: flex-start;
      gap: 24px;
    }

    &__item {
      display: flex;
      align-items: flex-end;
      gap: 4px;
      padding-bottom: 8px;

      color: #000;
      font-family: DM Sans;
      font-size: 18px;
      font-style: normal;
      font-weight: 500;
      line-height: 1.33;

      cursor: pointer;

      &__icon {
        font-size: 26px;
      }
    }
  }
}
</style>
