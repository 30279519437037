<script setup lang="ts">
import { useRoute } from "vue-router";
import { navLinks } from "@/router/navLinks";
import SidebarTabs from "@/components/atoms/SidebarTabs.vue";

function isActive(route: string) {
  const { path } = useRoute();
  return path === route;
}
</script>

<template>
  <div class="nav-links">
    <img
      src="@/assets/images/brand/logo_horizontal.svg"
      alt="Logo da Metha Energia"
      class="nav-links__logo"
    />
    <SidebarTabs
      v-for="{ title, icon, route } in navLinks"
      :key="`${title}`"
      :title="title"
      :icon="icon"
      :route="route"
      :active="isActive(route)"
    />
  </div>
</template>

<style lang="scss" scoped>
.nav-links {
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  gap: 10px;
  padding-top: 8px;
  width: 100%;

  &__logo {
    display: none;

    @media (min-width: 993px) {
      display: block;
      margin: 24px auto;
      max-width: 185px;
      width: 100%;
    }
  }
}
</style>
