import { AxiosRequestConfig, default as axios } from "axios";

export type Query = Record<string, unknown>;

declare module "axios" {
  export interface AxiosRequestConfig {
    public?: boolean;
  }
}

const zendeskApiUrl = "https://metha-energia.zendesk.com/api/v2/help_center";
const baseApiUrl = `${process.env.VUE_APP_BASE_URL}`;

async function getFaqSections(categoryId: number | string) {
  return axios.request({
    method: "GET",
    url: `${zendeskApiUrl}/categories/${categoryId}/sections`,
  });
}

async function getFaqArticles(sectionId: number) {
  return axios.request({
    method: "GET",
    url: `${zendeskApiUrl}/sections/${sectionId}/articles`,
  });
}

async function getFaqArticleContent(id: number) {
  return axios.request({
    method: "GET",
    url: `${zendeskApiUrl}/articles/${id}`,
  });
}

async function voteArticle(
  articleId: number,
  vote: "up" | "down",
  id: string,
  config: AxiosRequestConfig = {}
) {
  return axios.request({
    method: "POST",
    url: `${baseApiUrl}/zendesk/${articleId}/client/vote`,
    data: { value: vote, external_id: id },
    ...config,
  });
}

async function searchArticles(
  searchValue: string,
  categoryId: number | string
) {
  return axios.request({
    method: "GET",
    url: `${zendeskApiUrl}/articles/search?query=${searchValue}&category=${categoryId}`,
  });
}

export default {
  getFaqSections,
  getFaqArticles,
  getFaqArticleContent,
  voteArticle,
  searchArticles,
};
