<script setup lang="ts">
import { defineProps } from "vue";

import { useBreakpoints } from "@/libs/metha-components";
import { SimplifiedInvoice } from "@/types/InvoiceTypes";

import InvoiceListBodyRow from "@/components/molecules/InvoiceListBodyRow.vue";

const { lgAndUp } = useBreakpoints();

defineProps<{
  invoices: SimplifiedInvoice[];
}>();
</script>

<template>
  <div class="invoices-list-table">
    <div class="invoices-list-table__heading-row">
      <p>{{ lgAndUp ? "Referência" : "Mês" }}</p>
      <p class="invoices-list-table__heading-row--only-desktop">Vencimento</p>
      <p>Status</p>
      <p>Valor</p>
      <p class="invoices-list-table__heading-row--only-desktop">Fatura</p>
    </div>
    <InvoiceListBodyRow
      v-for="(invoice, index) in invoices"
      :key="`invoices-table-list-item-${index}`"
      :invoice="invoice"
    />
  </div>
</template>

<style lang="scss" scoped>
.invoices-list-table {
  width: 100%;

  &__heading-row {
    margin-bottom: 1.5rem;
    border-bottom: 2px solid #adb5bd;

    position: relative;
    width: 100%;
    display: grid;
    align-items: center;
    gap: 10px;

    color: #adb5bd;
    font-size: 16px;
    font-weight: 400;
    line-height: 20px;

    grid-template-columns: repeat(3, 1fr);

    @media (min-width: 993px) {
      grid-template-columns: repeat(5, 1fr);
    }

    p {
      width: fit-content;
      text-transform: capitalize;
    }

    &--only-desktop {
      display: none;

      @media (min-width: 993px) {
        display: unset;
      }
    }
  }
}
</style>
