import { defineStore } from "pinia";
import { useSessionStorage } from "@vueuse/core";

import {
  useAuthStore,
  useLoginStore,
  useInvoicesStore,
  usePersistentAuthStore,
  useWarningStore,
} from "@/stores";

import type { UserData } from "@/types/UserTypes";
import type { Installation, FlatInstallation } from "@/types/InstallationTypes";

import {
  flatInstallationsData,
  filterInstallationsByStatus,
} from "@/utils/installations";
import { handleError } from "@/utils/handleError";

import api from "@/api";
import { disableSmooch } from "@/plugins/sunshineChat";

const initialUserState = () => ({
  id: "",
  name: "",
  cpf: "",
  phone: "",
  token: "",
  pageTitle: "",
  isSidebarOpen: false,
  pageLoading: false,
  loadingError: "",
  installations: [] as FlatInstallation[],
  selectedInstallation: {} as FlatInstallation,
  loadingScreen: false,
  openSunshineChat: () => {
    return;
  },
  calledOpenChat: false,
});

export const useUserStore = defineStore("user", {
  state: () => useSessionStorage("@metha-energia/adc-user", initialUserState()),
  actions: {
    async logOutUser({ maintainLogin } = { maintainLogin: false }) {
      const authStore = useAuthStore();
      const persistentAuthStore = usePersistentAuthStore();
      const loginStore = useLoginStore();
      const invoicesStore = useInvoicesStore();
      const warningStore = useWarningStore();

      if (!maintainLogin) {
        persistentAuthStore.logOut();
        warningStore.clear();
      }

      await Promise.all([
        this.clear(),
        loginStore.clear(),
        authStore.clear(),
        invoicesStore.clear(),
        disableSmooch(),
      ]);
    },
    async clear() {
      Object.assign(this, initialUserState());
    },
    setUser(userData: UserData) {
      const persistentAuthStore = usePersistentAuthStore();
      persistentAuthStore.setDocument(userData.cpf);
      Object.assign(this, userData);
    },
    async getUser() {
      try {
        const authStore = useAuthStore();
        const token = await authStore.getToken("userStoreGetUser");

        const { data } = await api.getUser(
          { cpf: this.cpf },
          {},
          {
            headers: {
              Authorization: authStore.get(),
              "g-recaptcha-response": token,
            },
          }
        );

        if (Array.isArray(data.email)) {
          data.email = data.email[0];
        }

        if (Array.isArray(data.phone)) {
          data.phone = data.phone[0];
        }

        this.setUser(data);

        const filteredInstallations = filterInstallationsByStatus(
          data.installations,
          ["active", "approved", "under_cancellation", "suspended"]
        );

        this.setInstallations(filteredInstallations);
      } catch (error) {
        handleError(error, this.setLoadingError);
      }
    },
    findInstallationById(id: string | null) {
      if (!id) return null;

      const installations = this.installations ?? [];
      return installations.find((installation) => installation.id === id);
    },
    setInstallations(installations: Installation[]) {
      const flattedInstallations = flatInstallationsData(installations);
      this.installations = flattedInstallations;
    },
    async selectInstallation(installation: FlatInstallation) {
      const persistentAuthStore = usePersistentAuthStore();
      persistentAuthStore.setLastSessionInstallationId(String(installation.id));
      this.selectedInstallation = installation;
    },
    async switchInstallation(installation: FlatInstallation) {
      if (installation.id === this.selectedInstallation.id) return;

      try {
        this.pageLoading = true;
        const invoicesStore = useInvoicesStore();

        await invoicesStore.getAllInvoices(installation.id);

        this.selectedInstallation = installation;
      } catch (error) {
        handleError(error, this.setLoadingError);
      } finally {
        this.pageLoading = false;
      }
    },
    setToken(token: string) {
      this.token = token;
    },
    setPageTitle(title: string) {
      this.pageTitle = title;
    },
    switchSidebarMenu() {
      this.isSidebarOpen = !this.isSidebarOpen;
    },
    setLoadingError(err: string) {
      this.loadingError = err;
    },
    setOpenSunshineChat(openChat: () => void) {
      this.openSunshineChat = openChat;
    },
  },
});
