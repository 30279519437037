<script setup lang="ts">
import { useBreakpoints } from "@/libs/metha-components";
import { useUserStore } from "@/stores";

import LoggedIn from "@/layouts/LoggedIn.vue";
import LayoutHeader from "@/components/organisms/LayoutHeader.vue";
import LayoutSidebar from "@/components/organisms/LayoutSidebar.vue";
import SkeletonLoading from "@/components/organisms/SkeletonLoading.vue";

const userStore = useUserStore();
const { lgAndUp } = useBreakpoints();

const notificationNumber = 3;
</script>

<template>
  <LoggedIn>
    <aside class="sidebar">
      <LayoutSidebar v-if="lgAndUp" :notificationNumber="notificationNumber" />
    </aside>
    <header class="header">
      <LayoutHeader
        :title="userStore.pageTitle"
        :notificationNumber="notificationNumber"
        hideInstallationSwitchComponent
      />
    </header>
    <main class="main">
      <SkeletonLoading v-show="userStore.pageLoading" />
      <slot></slot>
    </main>
  </LoggedIn>
</template>

<style lang="scss" scoped>
.main {
  width: 100%;
  grid-area: main;
  background-color: #f5f2f5;

  @media (min-width: 993px) {
    max-width: calc(100vw - 200px);
    min-height: calc(100vh - 207px);
  }

  @media (min-width: 1020px) {
    max-width: calc(100vw - 258px);
  }
}

.sidebar {
  width: 100%;
  grid-area: sidebar;
}

.header {
  width: 100%;
  grid-area: header;
}
</style>
