<script lang="ts" setup>
import { useRoute, useRouter } from "vue-router";
import { MeIcon, MeButton } from "@/libs/metha-components";
import { useUserStore } from "@/stores";
import NavLinks from "@/components/molecules/NavLinks.vue";

const userStore = useUserStore();

const router = useRouter();
const route = useRoute();

function redirectToHelpCenterWithOpenChat() {
  if (route.path.includes("central-de-ajuda")) {
    userStore.openSunshineChat();
  } else {
    router.push({ path: "central-de-ajuda", query: { chat: "open" } });
  }
}
</script>

<template>
  <div class="sidebar">
    <NavLinks />

    <div class="sidebar__need-help">
      <div class="sidebar__need-help__container">
        <span class="sidebar__need-help__icon">
          <MeIcon>chat-outline</MeIcon>
        </span>
        Precisa de ajuda?
      </div>
      <div class="sidebar__need-help__button-container">
        <MeButton @click="redirectToHelpCenterWithOpenChat" block>
          Fale com a gente
        </MeButton>
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.sidebar {
  position: fixed;
  width: 100%;
  max-width: 258px;
  height: 100vh;
  border-right: 0.75px solid var(--neutral-300, #dee2e6);
  background: #fff;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  padding: 24px;

  &__need-help {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    align-self: stretch;
    border-radius: 16px;
    border: 1px solid #ffcc12;
    color: #000;
    font-family: DM Sans;
    font-size: 14px;
    font-style: normal;
    font-weight: 700;
    line-height: 24px;

    padding: 8px 4px;

    &__container {
      display: flex;
      justify-content: center;
      align-items: center;
      gap: 8px;
    }

    &__icon {
      background-color: rgba(255, 204, 18, 0.3);
      color: #000;
      font-size: 18px;

      display: inline-flex;
      align-items: center;
      justify-content: center;
      border-radius: 100%;
      width: 36px;
      height: 36px;
    }

    &__button-container {
      margin: 8px 0;
      width: 80%;
    }
  }
}
</style>
