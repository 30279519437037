<script setup lang="ts">
import { defineProps, computed } from "vue";
import {
  getInvoiceStatus,
  getInstallationStatus,
  getInvoiceStatusColor,
  getInstallationStatusColor,
} from "@/utils/status";

const props = defineProps<{
  status: string;
  invoice?: boolean;
}>();

const statusTranslated = computed(() => {
  let translatedStatus = "";

  if (props.invoice) {
    translatedStatus = getInvoiceStatus(props.status);
  } else {
    translatedStatus = getInstallationStatus(props.status);
  }

  return translatedStatus.toUpperCase();
});

const statusColor = computed(() => {
  if (props.invoice) {
    return getInvoiceStatusColor(props.status);
  }

  return getInstallationStatusColor(props.status);
});
</script>

<template>
  <div
    :style="{
      color: `rgb(${statusColor})`,
      'background-color': `rgba(${statusColor}, 0.2)`,
    }"
    class="status-tag"
  >
    <span class="status-tag__ball">&#9679;</span> {{ statusTranslated }}
  </div>
</template>

<style lang="scss" scoped>
.status-tag {
  border: none;
  border-radius: 0.5rem;
  font-size: 0.75rem;
  font-weight: bolder;
  line-height: 1;
  padding: 0.5rem;
  text-align: center;
  width: 100%;
  height: min-content;
  align-self: center;

  &__ball {
    margin-right: 0.375rem;
  }
}
</style>
