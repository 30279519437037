<script setup lang="ts">
import { onBeforeUnmount, ref, computed, watch, onMounted } from "vue";

import { MeIcon, MeButton, MeOtpCode } from "@/libs/metha-components";
import { Form } from "vee-validate";

import { useLoginStore, useUserStore } from "@/stores";

import { maskEmail } from "@/utils/mask";
import { handleError } from "@/utils/handleError";

const loginStore = useLoginStore();
const userStore = useUserStore();

const otpCode = ref("");
const loadingSeconds = ref(0);
const timerInterval = ref<number>();

const loading = ref(false);

const filteredEmail = computed(() => {
  return maskEmail(loginStore.email);
});

function startTimer() {
  if (loadingSeconds.value !== 0) return;

  loadingSeconds.value = 30;
  timerInterval.value = setInterval(() => {
    if (loadingSeconds.value === 1) {
      clearInterval(timerInterval.value);
    }
    loadingSeconds.value -= 1;
  }, 1000);
}

function abortTimer() {
  loadingSeconds.value = 0;
  clearInterval(timerInterval.value);
}

async function sendCode() {
  if (loadingSeconds.value !== 0) return;

  try {
    userStore.loadingScreen = true;

    startTimer();

    await loginStore.sendOtpCode();
  } catch (error) {
    abortTimer();

    if (loginStore.verificationMethod === "SMS") {
      loginStore.blockSmsVerification = true;
      changeMethod();
    } else {
      handleError(error, loginStore.setOtpError);
    }
  } finally {
    userStore.loadingScreen = false;
  }
}

async function handleSubmit() {
  if (otpCode.value.length !== 6 || loginStore.loadingOtp || loading.value)
    return;
  loading.value = true;
  userStore.loadingScreen = true;

  try {
    await loginStore.validateOtpCode(otpCode.value);
    await loginStore.redirectToInstallationSelection();
  } catch (error) {
    handleError(error, loginStore.setOtpError);
  } finally {
    loading.value = false;
    userStore.loadingScreen = false;
    loginStore.loadingOtp = false;
  }
}

function changeMethod() {
  if (loadingSeconds.value !== 0 && !loginStore.blockSmsVerification) return;

  loginStore.changeVerificationMethod();
  sendCode();
}

function goBackButtonSubmit() {
  loginStore.changeStatus("verify-cpf");
}

watch(otpCode, () => {
  loginStore.setOtpError("");
});

onMounted(async () => {
  loginStore.verificationMethod = loginStore.blockSmsVerification
    ? "e-mail"
    : "SMS";
  loginStore.setOtpError("");
  if (loginStore.shouldSendOtpCode) {
    sendCode();
  } else {
    startTimer();
  }
});

onBeforeUnmount(() => {
  abortTimer();
});
</script>

<template>
  <Form class="login__container login-verify-otp" @submit="handleSubmit">
    <p class="login-verify-otp__return me--link" @click="goBackButtonSubmit">
      <span class="login-verify-otp__return__icon-container">
        <MeIcon class="login-verify-otp__return__icon">arrow-down</MeIcon>
      </span>
      Voltar
    </p>
    <div class="login-verify-otp__container">
      <img
        src="@/assets/images/brand/logo_horizontal.svg"
        alt="Logo da Metha Energia"
        class="login-verify-otp__metha-logo"
        width="205"
      />
      <h2 class="login-verify-otp__title">Confirmar identidade</h2>
      <p class="login-verify-otp__register">
        <template v-if="loginStore.verificationMethod === 'SMS'">
          Por favor, digite o código que enviamos agora para o celular (**)
          *****-{{ loginStore.phone.slice(-4) }}
        </template>
        <template v-else
          >Enviamos um código de verificação para seu e-mail:
          {{ filteredEmail }}, confira em sua caixa de entrada e também na caixa
          de spam.</template
        >
      </p>
      <div class="login-verify-otp__code-container">
        <div class="login-verify-otp__code-container__resend-container">
          <p
            :class="[
              'login-verify-otp__code-container__resend-container__title',
              { 'login-verify-otp--error': loginStore.otpError },
            ]"
          >
            Código
          </p>
        </div>
        <MeOtpCode v-model="otpCode" :error="loginStore.otpError" />
        <p
          class="login-verify-otp--error"
          v-if="loginStore.otpError && loginStore.otpError.length > 0"
        >
          {{ loginStore.otpError }}
        </p>
      </div>
      <MeButton
        block
        :disabled="otpCode.length !== 6 || loginStore.loadingOtp || loading"
        class="login-verify-otp__otp-submit-button"
      >
        Entrar
      </MeButton>
      <p
        class="login-verify-otp__code-container__resend-container__button"
        @click="sendCode"
      >
        <template v-if="loadingSeconds !== 0">
          Código enviado! Aguarde {{ loadingSeconds }}s para reenviar.
        </template>
        <template v-else>
          <span
            class="login-verify-otp__code-container__resend-container__break-in-mobile"
          >
            Não recebeu?
            <span class="me--link cursor-pointer" @click.prevent="sendCode">
              enviar outro {{ loginStore.verificationMethod }}
            </span>
          </span>
          <template v-if="!loginStore.blockSmsVerification">
            ou
            <span class="me--link cursor-pointer" @click.prevent="changeMethod">
              enviar via
              {{ loginStore.verificationMethod === "SMS" ? "e-mail" : "SMS" }}
            </span>
          </template>
        </template>
      </p>
    </div>
    <div></div>
  </Form>
</template>

<style lang="scss" scoped>
.login-verify-otp {
  display: flex;
  flex-direction: column-reverse;
  justify-content: flex-end;

  @media (min-width: 993px) {
    flex-direction: column;
    justify-content: space-between;
    width: 50vw;
  }

  &__return {
    width: 100%;
    max-width: 495px;
    margin: 1rem auto 0;
    padding-left: 1.5rem;

    display: flex;
    align-items: flex-end;

    color: #667080;
    font-size: 1rem;
    font-weight: 500;

    @media (min-width: 993px) {
      margin-top: 0;
    }

    &__icon {
      transform: rotate(90deg);
      font-size: 16px;
      padding-bottom: 3px;
      margin-right: 3px;
    }
  }

  &__container {
    width: 100%;
    max-width: 495px;
    margin: 0 auto;
    padding: 1.5rem;
  }

  &__metha-logo {
    width: 230px;
    margin: 0 auto;

    @media (min-width: 993px) {
      width: 205px;
      margin: 0 auto;
    }
  }

  &__title {
    color: #000;
    font-feature-settings: "calt" off;
    font-weight: 700;
    font-size: 24px;
    line-height: 36px;

    margin: 2.5rem 0 0.5rem;

    @media (min-width: 993px) {
      font-size: 36px;
      line-height: 36px;
      letter-spacing: -0.72px;
      margin: 4rem 0 1.5rem;
    }
  }

  &__register {
    color: #6c757d;
    font-weight: 400;
    font-size: 16px;
    line-height: 20px;
  }

  &__code-container {
    margin: 2.5rem 0;

    &__resend-container {
      display: flex;
      gap: 1rem;
      align-items: center;
      justify-content: space-between;

      margin-bottom: 0.25rem;

      &__title {
        text-transform: uppercase;
        color: #000;
        font-size: 16px !important;
        font-weight: 500;
      }

      &__button {
        text-align: center;
        color: #6c757d;
        line-height: 1.5rem;
        margin-top: 2rem;
        text-decoration: none;
      }

      &__break-in-mobile {
        display: block;

        @media (min-width: 993px) {
          display: inline;
        }
      }
    }
  }

  &__otp-submit-button {
    width: 100%;
  }

  &--error {
    color: #ff0000;
    font-size: 14px;
    margin-top: 10px;
  }
}
</style>
