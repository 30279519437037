<script setup lang="ts">
import { computed, defineProps, onBeforeMount, ref } from "vue";
import { useRouter } from "vue-router";

import { toast } from "vue3-toastify";

import { Article, Section } from "@/types/Faq";
import { useFaqStore } from "@/stores/faq";
import { useUserStore } from "@/stores/user";

import FaqCardItem from "../atoms/FaqCardItem.vue";
import CaSpinner from "../atoms/CaSpinner.vue";

const props = defineProps<{
  section: Section;
  maxArticles?: number;
}>();

const router = useRouter();
const faqStore = useFaqStore();
const userStore = useUserStore();

const articles = ref<Article[]>([]);
const isOpen = ref(false);
const loading = ref(false);

const maxArticles = ref(props.maxArticles ?? 4);

const displayedArticles = computed(() => {
  return articles.value.slice(0, maxArticles.value);
});

const hiddenArticles = computed(() => {
  return articles.value.slice(maxArticles.value);
});

const hasExpandButton = computed(() => {
  return articles.value.length > maxArticles.value;
});

async function setCurrentArticle(article: Article) {
  try {
    userStore.loadingScreen = true;
    await faqStore.setCurrentSection(props.section.id);
    router.push(`central-de-ajuda/${article.id}`);
  } catch (error) {
    toast.error(
      "Ocorreu um erro ao selecionar o artigo. Tente novamente por favor",
      {
        position: toast.POSITION.BOTTOM_CENTER,
      }
    );
  } finally {
    userStore.loadingScreen = false;
  }
}

onBeforeMount(async () => {
  loading.value = true;
  articles.value = await faqStore.getArticles(props.section.id);
  loading.value = false;
});
</script>

<template>
  <div class="faq-section-card">
    <div class="faq-section-card__loading" v-if="loading">
      <CaSpinner :size="20" :width="5" />
    </div>
    <template v-else>
      <h2 class="faq-section-card__title">{{ section.name }}</h2>
      <div class="faq-section-card__container">
        <div
          v-for="(article, index) in displayedArticles"
          :key="`faq-section-${section.name}-card-${index}`"
        >
          <FaqCardItem
            :article="article"
            :setCurrentArticle="setCurrentArticle"
          />
          <div
            v-if="index < displayedArticles.length - 1 || isOpen"
            class="faq-section-card__line"
          />
        </div>
        <div
          v-for="(article, index) in hiddenArticles"
          :key="`faq-section-${section.name}-card-${index}`"
          v-show="isOpen"
        >
          <FaqCardItem
            :article="article"
            :setCurrentArticle="setCurrentArticle"
          />
          <div
            v-if="index < hiddenArticles.length - 1"
            class="faq-section-card__line"
          />
        </div>
      </div>
      <p
        v-if="hasExpandButton"
        @click="isOpen = !isOpen"
        class="faq-section-card__expander"
      >
        {{ isOpen ? "Menos" : "Mais" }} artigos
      </p>
    </template>
  </div>
</template>

<style lang="scss" scoped>
.faq-section-card {
  box-shadow: 0px 0px 1px 0px rgba(33, 37, 41, 0.08),
    0px 2px 2px 0px rgba(33, 37, 41, 0.06);
  border-radius: 1rem;
  background: #fff;
  height: 100%;

  position: relative;
  min-height: 100px;
  padding: 1.25rem 2rem;

  @media (min-width: 993px) {
    padding: 2.25rem 2rem;
  }

  &__loading {
    position: absolute;
    top: 50%;
    left: 50%;
    translate: transform(50%, -50%);
  }

  &__title {
    margin-bottom: 1.5rem;

    color: #000;
    font-size: 18px;
    font-weight: 700;
    line-height: 24px;

    @media (min-width: 993px) {
      font-size: 18px;
      line-height: 24px;
    }
  }

  &__container {
    display: flex;
    flex-direction: column;
    gap: 16px;
  }

  &__line {
    width: 100%;
    height: 1px;
    background: #d9d9d9;
    margin: 1rem auto 0;
  }

  &__expander {
    width: fit-content;
    display: block;

    margin: 1.25rem 0 0 auto;

    text-decoration: underline;
    color: #000;
    font-weight: 700;
    font-size: 14px;
    line-height: 24px;

    &:hover {
      cursor: pointer;
      color: #2db699;
    }
  }
}
</style>
