<script setup lang="ts">
import { defineProps } from "vue";
import { useUserStore } from "@/stores";
import { capitalize, formatCEP } from "@/utils/format";

import { Invoice } from "@/types/InvoiceTypes";

const userStore = useUserStore();

defineProps<{
  invoice: Invoice;
}>();
</script>

<template>
  <div class="user-installation-info">
    <div class="user-installation-info__basic">
      <p class="user-installation-info__basic__name">
        {{ capitalize(userStore.name) }}
      </p>
      <p>{{ capitalize(userStore.selectedInstallation.address) }}</p>
      <p
        v-if="
          userStore.selectedInstallation.zip_code &&
          userStore.selectedInstallation.zip_code.length
        "
      >
        CEP {{ formatCEP(userStore.selectedInstallation.zip_code) }}
      </p>
    </div>
    <div class="user-installation-info__advanced">
      <p>
        N° Instalação:
        {{ invoice.installation_number }}
      </p>
      <p>N° Cliente: {{ invoice.client_number }}</p>
      <p>
        {{
          capitalize(
            [
              invoice.subclass,
              invoice.tax_modality,
              invoice.connection_type,
            ].join(" ")
          )
        }}
      </p>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.user-installation-info {
  display: none;

  @media (min-width: 993px) {
    display: flex;
    justify-content: space-between;
    gap: 0.5rem;
    background: #f8f9fa;
    padding: 1rem 1.5rem;
    margin-bottom: 1.5rem;

    font-size: 16px;
    line-height: 24px;

    &__basic {
      max-width: 420px;

      &__name {
        font-weight: 700;
      }
    }

    &__advanced {
      text-align: right;
    }
  }
}
</style>
