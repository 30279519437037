<script setup lang="ts">
import { computed, ref, onBeforeMount } from "vue";
import { useRoute, useRouter } from "vue-router";

import { useUserStore, useInvoicesStore } from "@/stores";

import { handleError } from "@/utils/handleError";
import { orderByReferenceMonth } from "@/utils/orderInvoices";
import { formatDate, capitalize } from "@/utils/format";

import PaymentMethodsModalWithActivator from "@/components/organisms/PaymentMethodsModalWithActivator.vue";

import ViewInvoiceSelectInvoice from "@/components/molecules/ViewInvoiceSelectInvoice.vue";
import UserAndInstallationInfo from "@/components/molecules/UserAndInstallationInfo.vue";
import ConsumptionGraph from "@/components/molecules/ConsumptionGraph.vue";
import InvoiceTotalAndDiscountValues from "@/components/molecules/InvoiceTotalAndDiscountValues.vue";
import PixPayment from "@/components/molecules/PixPayment.vue";
import PastConsumptionGraph from "@/components/molecules/PastConsumptionGraph.vue";
import LastYearEconomy from "@/components/molecules/LastYearEconomy.vue";
import InvoiceOverrun from "@/components/molecules/InvoiceOverrun.vue";
import InvoiceMeasurementsDate from "@/components/molecules/InvoiceMeasurementsDate.vue";

import InvoiceViewStatusTag from "@/components/atoms/InvoiceViewStatusTag.vue";
import DownloadInvoiceButton from "@/components/atoms/DownloadInvoiceButton.vue";

const route = useRoute();
const router = useRouter();

const userStore = useUserStore();
const invoicesStore = useInvoicesStore();

const year = ref<number>(0);
const yearOptions = ref<number[]>([]);
const referenceMonthOptions = ref<{
  [key: number | string]: string[];
}>();

const hasInvoices = computed(() => {
  const invoices = invoicesStore.invoices ?? [];

  return invoices.length >= 1;
});

const invoice = computed(() => {
  return invoicesStore.selectedInvoice;
});

const isPaid = computed(() => {
  return invoice.value.status === "paid";
});

onBeforeMount(async () => {
  try {
    userStore.pageLoading = true;
    userStore.setPageTitle("Detalhes da Fatura");
    let id = route.params.id;
    id = Array.isArray(id) ? id[0] : id;

    await invoicesStore.getInvoiceById(id);
    await invoicesStore.getAllInvoices(userStore.selectedInstallation.id);

    if (!invoicesStore.selectedInvoice || !hasInvoices.value) {
      router.push("/faturas");
    }

    let invoices = orderByReferenceMonth(invoicesStore.invoices);
    const [, invoiceYear] =
      invoicesStore.selectedInvoice.reference_month.split("/");
    year.value = +invoiceYear;

    for (let i = 0; i < invoices.length; i += 1) {
      const item = invoices[i];
      const [, invoiceYear] = item.reference_month.split("/");
      yearOptions.value = [...new Set([...yearOptions.value, +invoiceYear])];

      if (!referenceMonthOptions.value) {
        referenceMonthOptions.value = {
          [invoiceYear]: [item.reference_month],
        };
      } else if (referenceMonthOptions.value[invoiceYear]) {
        referenceMonthOptions.value[invoiceYear] = [
          ...new Set([
            ...referenceMonthOptions.value[invoiceYear],
            item.reference_month,
          ]),
        ];
      } else {
        referenceMonthOptions.value = {
          ...referenceMonthOptions.value,
          [invoiceYear]: [item.reference_month],
        };
      }
    }
  } catch (error) {
    handleError(error, userStore.setLoadingError);
  } finally {
    userStore.pageLoading = false;
  }
});
</script>

<template>
  <section class="view-invoice" v-if="!userStore.pageLoading">
    <template v-if="invoice">
      <ViewInvoiceSelectInvoice
        :currentYear="year"
        :primaryOptions="yearOptions"
        :secondaryOptions="referenceMonthOptions"
        :currentInvoice="invoice.reference_month"
      />
      <section class="view-invoice__container">
        <div class="view-invoice__status-issue-date-wrapper">
          <p>
            <InvoiceViewStatusTag :status="invoice.status" />
          </p>
          <p>
            Data emissão:
            {{ invoice.issue_date ? formatDate(invoice.issue_date) : "--/--" }}
          </p>
        </div>
        <UserAndInstallationInfo :invoice="invoice" />
        <div class="view-invoice__dates">
          <p class="view-invoice__dates__reference-month">
            Referente a
            <span class="view-invoice__dates__main-value">
              {{ capitalize(invoice.reference_month) }}
            </span>
          </p>
          <p class="view-invoice__dates__due-date">
            Vencimento
            <span class="view-invoice__dates__main-value">
              {{ formatDate(invoice.due_date) }}
            </span>
          </p>
        </div>
        <div class="view-invoice__consumption-info">
          <div class="view-invoice__consumption-info__content">
            <div>
              <ConsumptionGraph
                :total="invoice.kwh_consumption"
                :active="invoice.kwh_injected"
                :discount="invoice.discount_percentage"
                mainColor="#FFCC12"
                secondaryColor="#E3E5E4"
              />
            </div>
            <InvoiceTotalAndDiscountValues :invoice="invoice" />
            <PixPayment
              v-if="!isPaid"
              auxiliarText="Pague com Pix"
              :invoice="invoice"
            />
            <div class="view-invoice__buttons">
              <div v-if="!isPaid" class="view-invoice__buttons__payments">
                <PaymentMethodsModalWithActivator :invoice="invoice" />
              </div>
              <div class="link view-invoice__buttons__download-invoice">
                <DownloadInvoiceButton
                  :invoice="invoice"
                  :disabled="!invoice.s3_url"
                  :url="invoice.s3_url"
                />
              </div>
            </div>
          </div>
        </div>
        <div class="view-invoice__past-discounts">
          <PastConsumptionGraph :invoices="invoicesStore.invoices" />
          <div class="view-invoice__past-discounts__side">
            <LastYearEconomy :invoice="invoice" />
            <InvoiceOverrun :invoice="invoice" />
          </div>
        </div>
        <InvoiceMeasurementsDate :invoice="invoice" />
      </section>
    </template>
  </section>
</template>

<style lang="scss" scoped>
.view-invoice {
  width: 100%;
  max-width: 100vw;
  padding-bottom: 1rem;

  @media (min-width: 993px) {
    max-width: unset;
  }

  &__container {
    width: calc(100% - 2rem);
    margin: 1.5rem 1rem;
    max-width: 950px;
    box-shadow: 0px 0px 1px 0px rgba(33, 37, 41, 0.08),
      0px 2px 2px 0px rgba(33, 37, 41, 0.06);
    background: #fff;
    border-radius: 1rem;
    padding: 1.5rem 1rem;

    @media (min-width: 993px) {
      margin: 1.5rem auto;
      padding: 1.5rem;
    }

    @media (min-width: 1020px) {
      max-width: 1180px;
      width: calc(100% - 6rem);
    }
  }

  &__status-issue-date-wrapper {
    text-align: center;
    padding-bottom: 1.5rem;

    font-size: 16px;
    line-height: 24px;
    color: #6c757d;

    @media (min-width: 993px) {
      display: flex;
      align-items: center;
      justify-content: space-between;
      gap: 0.5rem;
    }
  }

  &__dates {
    margin-bottom: 1.5rem;

    display: flex;
    justify-content: space-between;
    gap: 0.5rem;

    &__reference-month,
    &__due-date {
      display: flex;
      flex-direction: column;
      gap: 0.5rem;

      color: #6c757d;
      font-weight: 400;
      font-size: 16px;
      line-height: 16px;
      letter-spacing: 0.4px;

      @media (min-width: 1024px) {
        flex-direction: row;
        align-items: center;

        font-size: 20px;
        line-height: 24px;
        letter-spacing: unset;
      }
    }

    &__main-value {
      color: #111;
      font-weight: 700;
      font-size: 24px;
      line-height: 28px;

      @media (min-width: 993px) {
        font-size: 30px;
        line-height: 36px;
      }
    }
  }

  &__consumption-info {
    padding: 1.5rem 1rem 0;
    width: 100%;
    max-width: 980px;
    margin: 0 auto;

    @media (min-width: 993px) {
      border: 1px solid #ffcc12;
      border-radius: 1rem;
      padding: 2rem 1rem;
      margin: 0 auto 1rem;
    }

    @media (min-width: 1024px) {
      margin: 0 auto;
    }

    &__content {
      max-width: 500px;
      margin: 0 auto;
    }
  }

  &__buttons {
    width: 100%;
    margin: 1.5rem auto 0;

    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 1rem;

    @media (min-width: 993px) {
      flex-direction: row;
      margin: 1.25rem auto 0;
    }

    &__download-invoice {
      width: 100%;
      max-width: 440px;
    }

    &__payments {
      padding: 0 20px;
    }
  }

  &__past-discounts {
    display: none;

    @media (min-width: 972px) {
      width: 100%;
      max-width: 980px;
      margin: 3.375rem auto 3.375rem;
      display: flex;
      justify-content: space-between;
      gap: 1.5rem;

      &__side {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        align-items: stretch;
        gap: 1.25rem;
      }
    }
  }
}
</style>
