import { defineStore } from "pinia";
import { useSessionStorage } from "@vueuse/core";
import api from "@/api";
import { useUserStore } from "./user";
import { validPersistentJwt } from "@/types/Jwt";
import { usePersistentAuthStore } from "./persistentAuth";

const initialAuthState = () => ({
  authToken: "",
  sunshineJwt: "",
  getToken: (action: string): Promise<string> => {
    return new Promise(() => {
      return action;
    });
  },
});

export const useAuthStore = defineStore("auth", {
  state: () => useSessionStorage("@metha-energia/adc-auth", initialAuthState()),
  actions: {
    async clear() {
      this.authToken = "";
      this.sunshineJwt = "";
    },
    get() {
      return this.authToken;
    },
    set({ token, token_exp_date_in_milliseconds }: validPersistentJwt) {
      const persistentAuthStore = usePersistentAuthStore();
      persistentAuthStore.setJwt({ token, token_exp_date_in_milliseconds });
      this.authToken = token;
    },
    has() {
      return this.authToken && this.authToken.trim().length > 0;
    },
    setGetRecaptchaTokenFunction(func: (action: string) => Promise<string>) {
      this.getToken = func;
    },
    setSunshineJwt(jwt: string) {
      this.sunshineJwt = jwt;
    },
    async getAuthForChat() {
      const userStore = useUserStore();

      const { data } = await api.getAuthChat(
        userStore.id,
        {},
        {
          headers: {
            Authorization: this.get(),
            "g-recaptcha-response": await this.getToken("zendesk-chat-auth"),
          },
        }
      );

      this.setSunshineJwt(data.jwt);
    },
  },
});
