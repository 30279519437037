import {
  Installation,
  FlatInstallation,
  InstallationWithDetailedAddress,
} from "@/types/InstallationTypes";

function assembleAddress(detailedAddress: InstallationWithDetailedAddress) {
  const {
    street,
    address_number,
    address_supplement,
    neighborhood,
    city,
    uf,
    zip_code,
  } = detailedAddress;

  const supplement = address_supplement ? ` (${address_supplement})` : null;
  const zipCode = zip_code ? `CEP ${zip_code}` : null;

  const addressPieces = [
    street,
    address_number,
    supplement,
    neighborhood,
    city,
    uf,
    zipCode,
  ];

  return addressPieces.filter((piece) => Boolean(piece)).join(", ");
}

function isInstallationWithDetailedAddress(
  installation: Installation | InstallationWithDetailedAddress
): installation is InstallationWithDetailedAddress {
  return !!(installation as InstallationWithDetailedAddress).address_number;
}

function flatInstallationsData(installations: Installation[]) {
  const flattedInstallations = installations.map((installation) => {
    const flattedInstallation = {
      ...installation,
      address: "",
    } as FlatInstallation;

    if (isInstallationWithDetailedAddress(installation)) {
      flattedInstallation.address = assembleAddress(installation);
    } else {
      flattedInstallation.address = installation.street;
    }

    return flattedInstallation;
  });

  return flattedInstallations;
}

function filterInstallationsByStatus(
  installations: Installation[],
  allowedStatus: string[]
) {
  const filteredInstallations = installations.filter(({ status }) => {
    return allowedStatus.includes(status);
  });

  return filteredInstallations;
}

export { flatInstallationsData, filterInstallationsByStatus };
