<script setup lang="ts">
import { MeApp } from "@/libs/metha-components";

import { useUserStore, useWarningStore } from "@/stores";

import WarningModal from "@/components/molecules/WarningModal.vue";

const userStore = useUserStore();
const warningStore = useWarningStore();
</script>

<template>
  <MeApp id="me-app">
    <slot></slot>
    <!-- Supplier Warning -> Refaturamento de Março/2024 -->
    <WarningModal
      v-if="warningStore.showSupplierWarning"
      title="Atenção!"
      text="Caso a sua <b>fatura com referência a Março/24</b> ainda não tenha sido disponibilizada, não se preocupe. Ela será enviada em breve! A concessionária realizou um refaturamento e por isso os dados foram disponibilizados de forma mais tardia."
      icon="alert"
      closeButtonText="Entendi"
      :closeModal="warningStore.closeSupplierWarning"
    />
    <!-- Busy Chat Warning -> Alta demanda dentro do chat -->
    <WarningModal
      v-if="warningStore.showBusyChatWarning && userStore.calledOpenChat"
      title="Atenção!"
      text="Em virtude das atualizações em nossas plataformas de atendimento/área do cliente para trazer mais qualidade, estamos enfrentando uma alta demanda, por isso o tempo de retorno pode ser maior do que o usual. A sua dúvida será respondida assim que possível! Agradecemos a sua compreensão."
      icon="alert"
      closeButtonText="Entendi"
      :closeModal="warningStore.closeBusyChatWarning"
    />
  </MeApp>
</template>

<style lang="scss" scoped>
#me-app {
  background: #f2f2f2;

  width: 100vw;
  min-height: 100vh;

  display: flex;
  flex-direction: column;

  @media (min-width: 993px) {
    display: grid;
    grid-template-rows: auto auto;
    grid-template-columns: 258px auto;
    grid-template-areas:
      "sidebar header"
      "sidebar main";
  }
}
</style>
