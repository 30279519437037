<script setup lang="ts">
import { defineProps } from "vue";
import { getInvoiceStatus, getInvoiceStatusColor } from "@/utils/status";

import { computed } from "vue";

const props = defineProps<{
  status: string;
}>();

const statusTranslated = computed(() =>
  getInvoiceStatus(props.status).toUpperCase()
);

const statusColor = computed(() => getInvoiceStatusColor(props.status));
</script>

<template>
  <div
    :style="{
      color: `rgb(${statusColor})`,
      'background-color': `rgba(${statusColor}, 0.2)`,
    }"
    class="status-tag"
  >
    <span class="status-tag__ball">&#9679;</span> FATURA {{ statusTranslated }}
  </div>
</template>

<style lang="scss" scoped>
.status-tag {
  border: none;
  border-radius: 0.5rem;
  font-size: 14px;
  line-height: 12px;
  font-weight: bolder;
  margin-bottom: 1rem;
  padding: 0.5rem;
  text-align: center;
  width: 100%;
  height: min-content;
  align-self: center;

  &__ball {
    margin-right: 0.375rem;
  }

  @media (min-width: 993px) {
    font-size: 18px;
    padding: 0.75rem 1rem;
  }
}
</style>
