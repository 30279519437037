// #2DB699
const green = "45, 182, 153";
// #F1BD00
const yellow = "255, 204, 18";
// #DA4040
const red = "218, 64, 64";

interface CustomObjectList {
  [name: string]: string;
  default: string;
}

function getStatus(status: string, customStatus: CustomObjectList) {
  status = status?.toLowerCase() ?? "";

  const statusList = {
    ...customStatus,
  };

  if (Object.keys(statusList).includes(status)) {
    return statusList[status as keyof typeof statusList];
  }

  return statusList["default"];
}

function getStatusColor(status: string, customColorList: CustomObjectList) {
  status = status?.toLowerCase() ?? "";

  const colorList = {
    ...customColorList,
  };

  if (Object.keys(colorList).includes(status)) {
    return colorList[status as keyof typeof colorList];
  }

  return colorList["default"];
}

function getInvoiceStatus(status: string) {
  const translations = {
    queued: "na fila",
    open: "em aberto",
    paid: "paga",
    overdue: "vencida",
    canceled: "cancelada",
    failed: "erro",
    to_process: "em processamento",
    on_hold: "em espera",
    to_render: "renderizando",
    default: "desconhecido",
  };

  return getStatus(status, translations);
}

function getInvoiceStatusColor(status: string) {
  const colors = {
    paid: green,
    default: yellow,
    overdue: red,
    cancelled: red,
  };

  return getStatusColor(status, colors);
}

function getInstallationStatus(status: string) {
  const translations = {
    under_analysis: "Em Análise",
    reproved: "Reprovado",
    approved: "Aprovado",
    active: "Ativo",
    under_cancellation: "Em Cancelamento",
    canceled: "Cancelado",
    contract_canceled: "Contrato cancelado",
    inactive: "Inativo",
    suspended: "Suspenso",
    pending_update: "Atualização pendente",
    updated: "Contrato Atualizado",
    default: "Contrato Pendente",
  };

  return getStatus(status, translations);
}

function getInstallationStatusColor(status: string) {
  const colors = {
    active: green,
    updated: green,
    approved: green,
    inactive: yellow,
    under_analysis: yellow,
    under_cancellation: yellow,
    default: red,
    canceled: red,
    reproved: red,
    suspended: red,
    pending_update: red,
    contract_canceled: red,
  };

  return getStatusColor(status, colors);
}

export {
  getInvoiceStatus,
  getInvoiceStatusColor,
  getInstallationStatus,
  getInstallationStatusColor,
};
