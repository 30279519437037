<script setup lang="ts">
import { computed, defineProps } from "vue";
import { useBreakpoints } from "@/libs/metha-components";
import { formatDate, formatMoney } from "@/utils/format";

import StatusTag from "@/components/atoms/StatusTag.vue";
import ConsumptionGraph from "@/components/molecules/ConsumptionGraph.vue";
import PixPayment from "@/components/molecules/PixPayment.vue";
import DownloadInvoiceButton from "@/components/atoms/DownloadInvoiceButton.vue";
import type { SimplifiedInvoice } from "@/types/InvoiceTypes";

const props = defineProps<{
  lastInvoice: SimplifiedInvoice;
}>();

const { mdAndUp } = useBreakpoints();

const isPaid = computed(() => {
  return props.lastInvoice.status === "paid";
});
</script>

<template>
  <div class="adc__invoices__section last-invoice-details">
    <div class="last-invoice-details__header">
      <h3 class="last-invoice-details__header__title">Fatura mais recente</h3>
      <StatusTag
        :status="lastInvoice.status"
        invoice
        class="last-invoice-details__status last-invoice-details__status--hide-desktop"
      />
    </div>
    <div
      :class="[
        `last-invoice-details__structure ${
          isPaid
            ? 'last-invoice-details__structure__paid'
            : 'last-invoice-details__structure__debit'
        }`,
      ]"
    >
      <div class="last-invoice-details__graph">
        <div
          class="last-invoice-details__status last-invoice-details__status--hide-mobile"
        >
          <StatusTag :status="lastInvoice.status" invoice />
        </div>
        <ConsumptionGraph
          :total="lastInvoice.kwh_consumption"
          :active="lastInvoice.kwh_injected"
          :discount="lastInvoice.discount_percentage"
          mainColor="#FFCC12"
          secondaryColor="#E3E5E4"
        />
      </div>
      <div class="last-invoice-details__dates-and-payment">
        <!-- Referencia & Vencimento -->
        <div class="last-invoice-details__date-container">
          <div class="last-invoice-details__date-container__reference-month">
            <span class="last-invoice-details__date-container__text">
              Referente a
            </span>
            {{ lastInvoice.reference_month }}
          </div>
          <div class="last-invoice-details__date-container__due-date">
            <span class="last-invoice-details__date-container__text">
              Vencimento
            </span>
            {{ formatDate(lastInvoice.due_date) }}
          </div>
        </div>
        <!-- Pagamento por Pix - Desktop -->
        <div v-if="!isPaid && mdAndUp">
          <PixPayment auxiliarText="Pague com Pix" :invoice="lastInvoice" />
        </div>
      </div>
      <!-- Total & Desconto -->
      <div class="last-invoice-details__values-container">
        <div class="last-invoice-details__values-container__total-price">
          <span
            class="last-invoice-details__values-container__total-price__text"
          >
            Total {{ isPaid ? "pago" : "a pagar" }}
          </span>
          {{ formatMoney(lastInvoice.total_value) }}
        </div>
        <div class="last-invoice-details__values-container__discount">
          <span class="last-invoice-details__values-container__discount__text">
            Desconto aplicado
          </span>
          {{ formatMoney(lastInvoice.total_discount) }}
        </div>
      </div>
      <div class="last-invoice-details__button-container">
        <!-- Pagamento por Pix - Mobile -->
        <div v-if="!isPaid && !mdAndUp">
          <PixPayment auxiliarText="Pague com Pix" :invoice="lastInvoice" />
        </div>
        <!-- Abrir PDF da Fatura -->
        <div>
          <DownloadInvoiceButton
            :invoice="lastInvoice"
            :disabled="!lastInvoice.s3_url"
            :url="lastInvoice.s3_url"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.last-invoice-details {
  margin-top: 0;
  padding: 1rem 0.75rem;

  @media (min-width: 993px) {
    margin-top: 1rem;
    padding: 1rem;
  }

  &__structure {
    display: grid;
    column-gap: 3rem;
    row-gap: 1.5rem;
    align-items: flex-start;

    grid-template-rows: 1fr;

    grid-template-areas:
      "datesAndPayment"
      "graph"
      "totalValueAndDiscount"
      "openInvoice";

    &__paid {
      @media (min-width: 993px) {
        grid-template-columns: 1fr 1fr;
        grid-template-areas:
          "graph totalValueAndDiscount"
          "graph datesAndPayment"
          "graph openInvoice";
      }
    }

    &__debit {
      @media (min-width: 993px) {
        grid-template-columns: 1fr 1fr;
        grid-template-areas:
          "graph datesAndPayment"
          "totalValueAndDiscount openInvoice";
      }
    }
  }

  &__header {
    display: flex;
    align-items: center;
    justify-content: space-between;

    padding-bottom: 1.5rem;

    &__title {
      color: #6c757d;
      font-weight: 500;
      font-size: 1.125rem;
      line-height: 1.5rem;

      @media (min-width: 993px) {
        font-size: 1.25rem;
        line-height: 1.5rem;
      }
    }
  }

  &__status {
    width: fit-content;

    &--hide-desktop {
      @media (min-width: 993px) {
        display: none;
      }
    }

    &--hide-mobile {
      margin-bottom: 1rem;

      @media (max-width: 993px) {
        display: none;
      }
    }
  }

  &__graph {
    grid-area: graph;
    width: 100%;
  }

  &__dates-and-payment {
    grid-area: datesAndPayment;
  }

  &__date-container {
    color: #111;
    font-weight: 700;
    font-size: 1.5rem;
    line-height: 1.75rem;

    @media (min-width: 993px) {
      line-height: 2.25rem;
    }

    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-bottom: 1rem;

    &__text {
      color: #6c757d;
      font-weight: 400;
      letter-spacing: 0.4px;
      font-size: 1rem;
      line-height: 1rem;

      @media (min-width: 993px) {
        letter-spacing: unset;
        line-height: 1.25rem;
      }
    }

    &__reference-month {
      text-align: left;
    }

    &__due-date {
      text-align: right;
    }

    &__reference-month,
    &__due-date {
      display: flex;
      flex-direction: column;
      gap: 0.5rem;
    }
  }

  &__barcode {
    &__title {
      color: #6c757d;
      text-align: center;
      font-size: 16px;
      font-weight: 400;
      line-height: 20px;
      text-align: center;
      margin-bottom: 1rem;
    }
  }

  &__chart-container {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 1rem;

    @media (min-width: 993px) {
      padding: 0 1.25rem;
    }
  }

  &__values-container {
    grid-area: totalValueAndDiscount;
    width: 100%;
    display: flex;
    flex-direction: column-reverse;
    font-weight: 700;
    text-align: center;

    @media (min-width: 993px) {
      flex-direction: row;
      justify-content: space-between;
      max-width: unset;
    }

    &__total-price {
      color: #000;
      font-size: 2.5rem;
      line-height: 2.25rem;

      @media (min-width: 993px) {
        text-align: left;
        line-height: 3.125rem;
      }

      &__text {
        color: #6c757d;
        font-size: 1.25rem;
        line-height: 1.5rem;
      }
    }

    &__discount {
      color: #000;
      font-size: 1.5rem;
      line-height: 2.25rem;

      padding-bottom: 1.5rem;
      margin-bottom: 1.5rem;
      border-bottom: 1px solid #ced4da;

      @media (min-width: 993px) {
        text-align: right;
        padding-bottom: 0;
        margin-bottom: 0;

        border-bottom: none;
      }

      &__text {
        color: #6c757d;
        font-weight: 400;
        font-size: 1rem;
        line-height: 1.25rem;
      }
    }

    &__total-price,
    &__discount {
      display: flex;
      flex-direction: column;
    }
  }

  &__button-container {
    grid-area: openInvoice;
    width: 100%;

    display: flex;
    flex-direction: column;
    gap: 1rem;
  }
}
</style>
